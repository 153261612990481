import React, { useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, createTheme, CssBaseline, ThemeProvider, IconButton, Typography, Box, StyledEngineProvider } from '@mui/material';
import './App.css';
import styled from '@emotion/styled';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const EXAMPLE_PLAYERS: Player[] = [
  { name: 'Zidane', skill: 5 },
  { name: 'Ronaldinho', skill: 4 },
  { name: 'Ronaldo', skill: 9 },
  { name: 'Kaka', skill: 5 },
  { name: 'Nesta', skill: 8 },
  { name: 'Henry', skill: 2 },
  { name: 'Figo', skill: 6 },
  { name: 'Suarez', skill: 7 },
  { name: 'Messi', skill: 10 },
  { name: 'Funes Mori', skill: 1 },
];

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: "#3a3c3d",
      paper: '#3a3c3d',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato', sans-serif;
        }
      `,
    },
  },
});

interface Player {
  name: string;
  skill: number;
}

const PaperContainer = styled(Paper)`
  background: inherit;
  width: 100%;
`;

const TeamsContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > :nth-child(2) { margin-left: 10vw; }

`
const UnassignedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const balanceTeams = (players: Player[], teamsArg?: Array<Player[]>) => {
  const teams: Array<Player[]> = teamsArg || [[], []];
  players.sort((a, b) => a.skill <= b.skill ? 1 : -1);
  const mostPlayers = teamsArg && teamsArg[1].length < teamsArg[0].length ? 1 : 0
  let currTeam = mostPlayers || 0;
  let pick = null;

  while (players.length) {
    // if previous team picked a better player than next pick
    if (players.length && pick && players.length > 1 && players[0].skill < pick.skill) {
      const extraPick: Player = players.shift() as Player;
      teams[currTeam].push(extraPick);
    }
    pick = players.shift() as Player;
    teams[currTeam].push(pick);

    currTeam = currTeam === 0 ? 1 : 0;
  }

  return teams;
}

const PlayerList: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>(EXAMPLE_PLAYERS);
  const [teams, setTeams] = useState<Array<Player[]>>([[], []]);
  const [newPlayerName, setNewPlayerName] = useState('');
  const [newPlayerSkill, setNewPlayerSkill] = useState('');

  const handleAddPlayer = () => {
    setPlayers([...players, { name: newPlayerName, skill: Number(newPlayerSkill) }]);
    setNewPlayerName('');
    setNewPlayerSkill('');
  };

  const handleSetTeams = () => {
    const resultTeams = balanceTeams(players, teams);
    setTeams(resultTeams);
    setPlayers([]);
  };

  const handleReset = () => {
    setTeams([[], []]);
    setPlayers([]);
  }

  const onDragEnd = (result: any) => {
    const { destination, source, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === "unassigned" && source.droppableId === "unassigned") {
      return;
    }

    if (destination.droppableId === "unassigned") { // moving to unassigned
      const teamIndex = source.droppableId === 'team-1' ? 0 : 1;
      const player = teams[teamIndex][source.index];

      let teamsBuff = [[...teams[0]], [...teams[1]]];
      let playersBuff = [...players];
      teamsBuff[teamIndex].splice(source.index, 1);
      playersBuff.splice(destination.index, 0, player);
      setTeams(teamsBuff);
      setPlayers(playersBuff);
      return;
    }

    if (source.droppableId === "unassigned") { // moving from unassigned
      const teamIndex = destination.droppableId === 'team-1' ? 0 : 1;
      const player = players[source.index];

      let teamsBuff = [[...teams[0]], [...teams[1]]];
      let playersBuff = [...players];
      playersBuff.splice(source.index, 1);
      teamsBuff[teamIndex].splice(destination.index, 0, player);
      setTeams(teamsBuff);
      setPlayers(playersBuff);
      return;
    }

    if (destination) { // between teams.
      const teamIndex = source.droppableId === 'team-1' ? 0 : 1;
      const player = teams[teamIndex][source.index];
      const targetTeamIndex = destination.droppableId === 'team-1' ? 0 : 1;
      const targetTeam = teams[targetTeamIndex];

      let teamsBuff = [[...teams[0]], [...teams[1]]];
      teamsBuff[teamIndex].splice(source.index, 1);
      teamsBuff[targetTeamIndex].splice(destination.index, 0, player);
      setTeams(teamsBuff);
    }
  };

  return (
    <MainContainer className="App">
      <Typography variant="h4">Team Balancer</Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '500px',
          marginTop: '20px'
        }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          <TextField label="Name" value={newPlayerName} onChange={(e) => setNewPlayerName(e.target.value)} />
          <TextField label="Skill" value={newPlayerSkill} onChange={(e) => setNewPlayerSkill(e.target.value)} />
          <IconButton disabled={!newPlayerName.length || !newPlayerSkill} onClick={handleAddPlayer}><AddCircleOutlineIcon /></IconButton>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: "20px" }}>
          <Button onClick={handleSetTeams} variant='contained' sx={{ width: "40%" }}><Typography fontSize={"30px"}>⚖</Typography></Button>
          <Button onClick={handleReset} variant='contained' sx={{ width: "40%" }}><Typography fontSize={"30px"}>🗑️</Typography></Button>
        </div>
      </div>


      <DragDropContext onDragEnd={onDragEnd}>

        <TeamsContainer>
          {!teams[0].length ? null :
            <Droppable droppableId="team-1" type="team">
              {(provided) => (
                <TableContainer component={PaperContainer} ref={provided.innerRef} {...provided.droppableProps}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <PeopleAltIcon sx={{ color: 'red' }} />
                            <span style={{ marginLeft: '10px' }}>{teams[0].reduce((acc, curr) => curr.skill + acc, 0)}</span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="20px">🎯</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teams[0].map((player, index) => (
                        <Draggable key={player.name} draggableId={player.name} index={index}>
                          {(provided, snapshot) => (
                            <TableRow
                              ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                            >
                              <TableCell component="th" scope="row">
                                {player.name}
                              </TableCell>
                              <TableCell align="right">{player.skill}</TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Droppable>
          }

          {!teams[1].length ? null :
            <Droppable droppableId="team-2" type="team">
              {(provided) => (
                <TableContainer component={PaperContainer} ref={provided.innerRef} {...provided.droppableProps}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <span style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <PeopleAltIcon sx={{ color: 'blue' }} />
                            <span style={{ marginLeft: '10px' }}>{teams[1].reduce((acc, curr) => curr.skill + acc, 0)}</span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="20px">🎯</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teams[1].map((player, index) => (
                        <Draggable key={player.name} draggableId={player.name} index={index}>
                          {(provided) => (
                            <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <TableCell component="th" scope="row">
                                {player.name}
                              </TableCell>
                              <TableCell align="right">{player.skill}</TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Droppable>
          }</TeamsContainer>

        <Droppable droppableId="unassigned" type="team">
          {(provided) => (
            <TableContainer component={PaperContainer} ref={provided.innerRef} {...provided.droppableProps} style={{ width: "80vw", marginTop: "10px" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Unassigned</TableCell>
                  <TableCell align="right"><Typography fontSize="20px">🎯</Typography></TableCell>
                </TableRow>
              </TableHead>
              {players.map((player, index) => (
                <Draggable key={player.name} draggableId={player.name} index={index}>
                  {(provided) => (
                    <TableRow ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <TableCell component="th" scope="row">
                        {player.name}
                      </TableCell>
                      <TableCell align="right">{player.skill}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => {
                          setPlayers(players.filter(p => p.name !== player.name));
                        }}><RemoveCircleOutlineIcon /></IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </TableContainer>
          )}
        </Droppable>
      </DragDropContext>
    </MainContainer >
  );
};


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PlayerList />
    </ThemeProvider>
  );
}

export default App;
